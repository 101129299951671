import TrackingManager, { TApplicationInsightsConfig } from '@ingeniorforeningen/tracking';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const useGtmProdContainer = process.env.NEXT_PUBLIC_USE_GTM_PROD_CONTAINER === 'true';
const trackingLanguage = 'da' as TrackingManager['locale']; // Set the default tracking language
const cloudRoleName = 'IdaUI';
const connectionString: string = process.env.NEXT_PUBLIC_APP_INSIGHTS_CONNECTIONSTRING as string;

const applicationInsightConfig: TApplicationInsightsConfig = {
  connectionString,
  cloudRoleName,
  // we cast to any because of some internal typings issue in microsoft:
  // https://github.com/microsoft/applicationinsights-angularplugin-js/issues/97#issuecomment-1435039094
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extensions: [reactPlugin as any],
  enableAutoRouteTracking: true,
};

const trackingManager = new TrackingManager(
  useGtmProdContainer,
  'ida.dk',
  trackingLanguage,
  applicationInsightConfig,
);

const tracker = {
  trackPageView: (name: string, id: string) => {
    if (name && id) {
      trackingManager.page.view({
        pageTitle: name,
        nodeId: id,
        section: 'ida UI',
      });
    }
  },
  trackPageError: (errorcode: number, properties?: Record<string, unknown>) => {
    trackingManager.page.error({
      errorcode,
      properties,
      severityLevel: 3,
    });
  },

  monitor: trackingManager.monitor,
};

export { reactPlugin, tracker as default };
